import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import { convert } from "html-to-text";
import React from "react";
import { FaStickyNote } from "react-icons/fa";
import { t } from "../translate";
import { IApiArticlesFragment } from "../types";
import { GDChCard } from "./card";
// import { hyphenateSync as hyphenateDe } from "hyphen/de";
// import { hyphenateSync as hyphenateEn } from "hyphen/en";

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
};

export function Article({
  article,
  lang,
}: {
  article: IApiArticlesFragment;
  lang: string;
}) {
  const description =
    convert(article.content).length > 200
      ? convert(article.content).slice(0, 200) + "…"
      : convert(article.content);

  return (
    <GDChCard sx={{ hyphens: "auto" }}>
      {article.featuredImage && (
        <CardMedia
          component="img"
          image={article.featuredImage.url}
          loading="lazy"
        />
      )}
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "green" }}>
            <FaStickyNote />
          </Avatar>
        }
        title={new Date(article.pubDate).toLocaleString(
          lang === "en" ? "en-GB" : "de-DE",
          dateOptions
        )}
      />
      <CardContent>
        <Typography variant="h6" component="div" sx={{ lineHeight: "1.2em" }}>
          {article.title}
        </Typography>
        <Typography sx={{ lineHeight: "1.2em", mt: 1 }}>
          {article.subtitle}
        </Typography>
        <Typography sx={{ lineHeight: "1.2em", mt: 2, fontSize: "1.5em" }}>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title={t("In App öffnen")}>
          <a href={`https://gdch.app/article/${article.slug}`} target="_blank">
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                margin: 1,
                paddingTop: "10px",
                borderRadius: 100,
              }}
            >
              {t("Öffnen")}
            </Button>
          </a>
        </Tooltip>
      </CardActions>
    </GDChCard>
  );
}
