import { Masonry } from "@mui/lab";
import { Box, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import useSWR from "swr";
import { Article } from "./components/article";
import { Journal } from "./components/journal";
import { fetcher, theme } from "./config";
import { IApiArticlesFragment, ILatestPublicationIssueQuery } from "./types";

function Container() {
  const [lang, setLang] = useState("de");

  useEffect(() => {
    if (window.location.href.includes("//en.gdch.de")) {
      setLang("en");
    }
  }, []);

  const {
    data: entries,
    error,
    isLoading,
  } = useSWR<{
    data: (
      | IApiArticlesFragment
      | ILatestPublicationIssueQuery["publicationIssues"][0]
    )[];
  }>(
    `https://data.gdch.app/api/articles/gdch-de${lang === "en" ? "/en" : ""}`,
    fetcher
  );

  return (
    <Box sx={{ marginRight: { xs: -2, sm: -2, md: -3 } }}>
      <Masonry
        spacing={{ xs: 2, sm: 2, md: 3 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {!error &&
          !isLoading &&
          entries &&
          entries.data.map((entry) => {
            if (entry.__typename === "Article") {
              return <Article article={entry} key={entry.id} lang={lang} />;
            } else if (entry.__typename === "PublicationIssue") {
              return <Journal journal={entry} key={entry.id} />;
            }
          })}
      </Masonry>
    </Box>
  );
}

const rootNode = document.getElementById("react-root");
const root = createRoot(rootNode);
root.render(
  <ThemeProvider theme={theme}>
    <Container />
  </ThemeProvider>
);
