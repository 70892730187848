import React from "react";
import { Card, CardProps } from "@mui/material";

export function GDChCard(props: CardProps) {
  return (
    <Card
      style={{ borderRadius: 0 }}
      variant="outlined"
      elevation={0}
      {...props}
    />
  );
}
