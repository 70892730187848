import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { FaBookOpen } from "react-icons/fa";
import { t } from "../translate";
import { ILatestPublicationIssueQuery } from "../types";
import { GDChCard } from "./card";

const dateOptions: Intl.DateTimeFormatOptions = {
  month: "long",
  year: "numeric",
};

export function Journal({
  journal,
}: {
  journal: ILatestPublicationIssueQuery["publicationIssues"][0];
}) {
  return (
    <GDChCard>
      {journal.cover && (
        <CardMedia component="img" image={journal.cover.url} loading="lazy" />
      )}
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#005C95" }}>
            <FaBookOpen />
          </Avatar>
        }
        title={new Date(journal.pubDate).toLocaleString(
          window.location.href.includes("//en.gdch.de") ? "en-GB" : "de-DE",
          dateOptions
        )}
        subheader={t("Aktuelle Ausgabe")}
      />
      <CardContent>
        <Typography variant="h6" component="div" sx={{ lineHeight: "1.2em" }}>
          {t("GDCh-Mitgliedermagazin")}
          <br />
          <em>Nachrichten aus der Chemie</em>
        </Typography>
        <Typography sx={{ lineHeight: "1.2em", mt: 1 }}>
          {journal.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title={t("In App öffnen")}>
          <a
            href={`https://gdch.app/nachrichten-aus-der-chemie/${journal.slug}`}
            target="_blank"
          >
            <Button
              variant="contained"
              color="blue"
              sx={{
                boxShadow: "none",
                margin: 1,
                paddingTop: "10px",
                borderRadius: 100,
              }}
            >
              {t("Öffnen")}
            </Button>
          </a>
        </Tooltip>
      </CardActions>
    </GDChCard>
  );
}
