const data = {
  Öffnen: "Open",
  "In App öffnen": "Open in app",
  Präsenz: "on-site",
  heute: "today",
  morgen: "tomorrow",
  Tage: "days",
  Teilen: "Share",
  Tagung: "symposium",
  Fortbildung: "course",
  Vortrag: "talk",
  Stammtisch: "round table",
  Exkursion: "excursion",
  Andere: "misc",
  Zurücksetzen: "reset",
  Suchbegriff: "search",
  "Karte anzeigen": "Show map",
  "ICS-Datei / Apple": "ICS file / Apple",
  Kalender: "Calendar",
  "GDCh-Mitgliedermagazin": "GDCh member magazine",
  "Aktuelle Ausgabe": "Current issue",
};

export function t(key: string): string {
  if (window.location.href.includes("//en.gdch.de") && key in data) {
    return data[key];
  }
  return key;
}
