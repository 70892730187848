import { TEventType, TGdchColor } from "./types";
import { createTheme } from "@mui/material";

export const palette = {
  primary: {
    main: "#74a43e",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#19857b",
    contrastText: "#ffffff",
  },
  black: {
    main: "#000000",
    contrastText: "#ffffff",
  },
  pink: {
    main: "#DF1995",
    light: "#eeaaaa",
    contrastText: "#ffffff",
  },
  blue: {
    main: "#005C95",
    contrastText: "#ffffff",
  },
  yellow: {
    main: "#F8AA00",
    contrastText: "#000000",
  },
  green: {
    main: "#64A70B",
    contrastText: "#ffffff",
  },
  gray: {
    main: "#dddddd",
    contrastText: "#000000",
  },
  dark: {
    main: "#666",
    contrastText: "#ffffff",
  },
};

export const theme = createTheme({
  palette: palette,
  typography: {
    fontFamily: [
      '"HelveticaNeue"',
      "Roboto",
      "-apple-system",
      "sans-serif",
      "Arial",
    ].join(","),
  },
});

export const eventTypeToColor: (input: TEventType) => TGdchColor = (i) => {
  if (i === "academy") return "pink";
  else if (i === "talk") return "green";
  else if (i === "excursion") return "yellow";
  else if (i === "recurring_meetup") return "black";
  else if (i === "symposia") return "blue";
  else if (i === "other") return "yellow";
  else return "yellow";
};

export const fetcher = async (url) => {
  const res = await fetch(url);
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data");
    error.stack = `${res.status}: ${res.statusText}`;
    throw error;
  }
  return res.json();
};
